import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MarketingClaims.style';

/** @namespace Scandipwa/Component/MarketingClaims/Component */
export class MarketingClaimsComponent extends PureComponent {
    static propTypes = {
        marketingClaims: PropTypes.instanceOf(Array).isRequired
    };

    renderMarketingClaims() {
        const { marketingClaims } = this.props;
        return marketingClaims && marketingClaims.length ? (
            <div block="MarketingClaims">
                { marketingClaims.map(
                    (singleClaim) => this.renderSingleClaim(singleClaim)
                ) }
            </div>
        ) : null;
    }

    renderSingleClaim(attribute) {
        const { label } = attribute;
        return (
            <div key={ label } block="MarketingClaims" elem="MarketingClaimButton">
                <p>{ label }</p>
            </div>
        );
    }

    render() {
        return (
            this.renderMarketingClaims()
        );
    }
}

export default MarketingClaimsComponent;
