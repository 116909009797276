import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Scandipwa/Component/TypographyParagraph/Component */
export class TypographyParagraphComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.string.isRequired,
        size: PropTypes.oneOf(['small', 'big', 'normal']),
        color: PropTypes.oneOf(['black', 'white'])
    };

    static defaultProps = {
        color: 'black',
        size: 'normal'
    };

    render() {
        const { children, size, color } = this.props;
        return (
            <p block="TypographyParagraph" mods={ { size, color } }>
                { children }
            </p>
        );
    }
}

export default TypographyParagraphComponent;
