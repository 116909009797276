import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import MarketingClaims from './MarketingClaims.component';

/** @namespace Scandipwa/Component/MarketingClaims/Container */
export class MarketingClaimsContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    containerProps = () => ({
        marketingClaims: this._getMarketingClaims()
    });

    _getMarketingClaims() {
        const { product } = this.props;
        const marketingClaims = product?.attributes?.marketing_claims?.attribute_options;
        const marketingArray = marketingClaims ? Object.values(marketingClaims) : [];
        return marketingArray;
    }

    render() {
        return (
            <MarketingClaims
              { ...this.containerProps() }
            />
        );
    }
}

export default MarketingClaimsContainer;
