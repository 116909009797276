import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';

import { sortObjectByOrder } from 'Util/Helpers/SortObjectByOrder';

import './ProductIcons.style';

/** @namespace Scandipwa/Component/ProductIcons/Component */
export class ProductIconsComponent extends PureComponent {
    static propTypes = {
        productIcons: PropTypes.arrayOf(PropTypes.shape({
            tag_name: PropTypes.string,
            tag_code: PropTypes.string,
            tag_url: PropTypes.string,
            image_url: PropTypes.string,
            description: PropTypes.string,
            sort_order: PropTypes.number
        })).isRequired,
        isBigSquare: PropTypes.bool.isRequired
    };

    renderIconLink(icon) {
        const {
            tag_name, tag_url, image_url
        } = icon;

        return tag_url ? (
            <a href={ tag_url }><img src={ image_url } alt={ tag_name } /></a>
        ) : (<img src={ image_url } alt={ tag_name } />);
    }

    renderSingleIcon(icon) {
        const { isBigSquare } = this.props;
        const {
            tag_name, tag_code, description
        } = icon;

        return (
            <div
              data-tip={ description }
              key={ tag_code }
              title={ tag_name }
              block="ProductIcons"
              elem="Icon"
              mods={ { isBigSquare } }
            >
                { this.renderIconLink(icon) }
                <ReactTooltip
                  className="ProductIcons-Icon-Tooltip"
                  place="top"
                  backgroundColor="#f3f8fb"
                  textColor="#9ea3a9"
                  effect="solid"
                  multiline
                />
            </div>
        );
    }

    renderIcons() {
        const { productIcons, isBigSquare } = this.props;
        sortObjectByOrder(productIcons, 'sort_order');

        return productIcons && productIcons.length ? (
            <div
              block="ProductIcons"
              mods={ { isBigSquare } }
            >
                { productIcons.map((icon) => this.renderSingleIcon(icon)) }
            </div>
        ) : null;
    }

    render() {
        return this.renderIcons();
    }
}

export default ProductIconsComponent;
