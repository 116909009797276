import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    BreadcrumbsDispatcher,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    ProductPageContainer as SourecProductPageContainer
} from 'SourceRoute/ProductPage/ProductPage.container';
import ProductReducer from 'Store/Product/Product.reducer';
import { withReducers } from 'Util/DynamicReducer';

export * from 'SourceRoute/ProductPage/ProductPage.container';

/** @namespace Scandipwa/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateBreadcrumbs: (breadcrumbs, prevCategoryId, isMobile) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithProduct(breadcrumbs, prevCategoryId, isMobile, dispatch)
    )
});

/** @namespace Scandipwa/Route/ProductPage/Container */
export class ProductPageContainer extends SourecProductPageContainer {
    updateBreadcrumbs() {
        const { updateBreadcrumbs, location, device: { isMobile } = {} } = this.props;
        const { state: { prevCategoryId = null } = {} } = location;
        updateBreadcrumbs(this.getDataSource(), prevCategoryId, isMobile);
    }
}

export default withReducers({
    ProductReducer
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer)
));
