import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import TypographyHeader from 'Component/TypographyHeader';
import TypographyParagraph from 'Component/TypographyParagraph';

import './GroupOfAttributes.style';

/** @namespace Scandipwa/Component/GroupOfAttributes/Component */
export class GroupOfAttributesComponent extends PureComponent {
    static propTypes = {
        groupOfAttributes: PropTypes.instanceOf(Array).isRequired,
        attributeGroupTitle: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
        className: ''
    };

    renderAttributesGroupTitle() {
        const { attributeGroupTitle } = this.props;
        const titleWordsArray = attributeGroupTitle.split(' ');
        const titleBluePart = titleWordsArray.pop();
        const titleBlackPart = titleWordsArray.join(' ').concat(' ');

        return attributeGroupTitle.length ? (
            <div block="GroupOfAttributes" elem="Heading">
                <TypographyHeader heading="h2">
                    { __(titleBlackPart) }
                    <span className="TypographyHighlight">
                     { __(titleBluePart) }
                    </span>
                </TypographyHeader>
            </div>
        ) : null;
    }

    renderSingleAttribute(attribute) {
        const { attribute_label, attribute_value } = attribute;

        if (!attribute_value) {
            return null;
        }

        return (
            <div block="GroupOfAttributes" elem="SingleAttribute">
                <TypographyHeader heading="h4">{ attribute_label }</TypographyHeader>
                <TypographyParagraph size="normal">
                    <Html content={ attribute_value } />
                </TypographyParagraph>
            </div>
        );
    }

    renderListOfAttributes() {
        const { groupOfAttributes } = this.props;
        return (
            <div block="GroupOfAttributes" elem="AttributeWrapper">
                { groupOfAttributes.map(
                    (singleAttribute) => this.renderSingleAttribute(singleAttribute)
                ) }
            </div>
        );
    }

    renderGroupOfAttributes() {
        const { groupOfAttributes, className } = this.props;
        return groupOfAttributes && groupOfAttributes.length ? (
            <div block="GroupOfAttributes" className={ className }>
                <div block="GroupOfAttributes" elem="Wrapper">
                    { this.renderAttributesGroupTitle() }
                    <div block="GroupOfAttributes" elem="Attributes">
                        { this.renderListOfAttributes() }
                    </div>
                </div>
            </div>
        ) : null;
    }

    render() {
        return this.renderGroupOfAttributes();
    }
}

export default GroupOfAttributesComponent;
