/** @namespace Scandipwa/Util/Helpers/SortObjectByOrder/sortObjectByOrder */
export const sortObjectByOrder = (object, orderPropName) => {
    object.sort((a, b) => {
        if (a[orderPropName] === 0 || b[orderPropName] === 0) {
            return -1;
        }
        if (a[orderPropName] > b[orderPropName]) {
            return 1;
        }
        if (a[orderPropName] < b[orderPropName]) {
            return -1;
        }

        return a - b;
    });
};
