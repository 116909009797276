import PropTypes from 'prop-types';

import ProductTagsQuery from 'Query/ProductTags.query';
import DataContainer from 'Util/Request/DataContainer';

import ProductIcons from './ProductIcons.component';

/** @namespace Scandipwa/Component/ProductIcons/Container */
export class ProductIconsContainer extends DataContainer {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        withAttribute: PropTypes.string,
        allWithoutAttribute: PropTypes.string,
        isBigSquare: PropTypes.bool
    };

    static defaultProps = {
        withAttribute: null,
        allWithoutAttribute: null,
        isBigSquare: false
    };

    state = {
        productIcons: []
    };

    componentDidMount() {
        const { productId } = this.props;
        if (productId) {
            this._getProductTags();
        }
    }

    componentDidUpdate(prevProps) {
        const { productId } = this.props;
        if (productId && productId !== prevProps.productId) {
            this._getProductTags();
        }
    }

    containerProps = () => {
        const { isBigSquare } = this.props;
        const { productIcons } = this.state;
        return { productIcons, isBigSquare };
    };

    filterIconsByAttributes(icons) {
        const { withAttribute, allWithoutAttribute } = this.props;

        if (withAttribute) {
            return icons.filter(({ attribute_code }) => attribute_code === withAttribute);
        }

        if (allWithoutAttribute) {
            return icons.filter(({ attribute_code }) => attribute_code !== allWithoutAttribute);
        }

        return icons;
    }

    _getProductTags() {
        const { productId } = this.props;
        this.fetchData(
            [ProductTagsQuery.getProductTags({ product_id: productId })],
            ({ productTagsByProductId: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ productIcons: this.filterIconsByAttributes(items) });
            }
        );
    }

    render() {
        return (
            <ProductIcons
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductIconsContainer;
