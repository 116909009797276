import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductLabelQuery from 'Query/ProductLabel.query';
import { fetchQuery } from 'Util/Request/Query';

import ProductLabel from './ProductLabel.component';

/** @namespace Scandipwa/Component/ProductLabel/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStoreId: state.ConfigReducer.id
});

/** @namespace Scandipwa/Component/ProductLabel/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ProductLabel/Container */
export class ProductLabelContainer extends PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        currentStoreId: PropTypes.number.isRequired,
        ingridientsTableHeading: PropTypes.string.isRequired,
        nutritionsTableHeading: PropTypes.string.isRequired
    };

    state = {
        productLabelsTitle: [],
        productLabels: [],
        nutritionalValuesTitle: [],
        nutritionalValues: []
    };

    componentDidMount() {
        const { productId, currentStoreId } = this.props;
        if (productId && currentStoreId) {
            this._getProductLabels(productId);
        }
    }

    componentDidUpdate(prevProps) {
        const { productId } = this.props;
        if (prevProps.productId !== productId) {
            this._getProductLabels(productId);
        }
    }

    containerProps = () => {
        const {
            productLabels,
            productLabelsTitle,
            nutritionalValuesTitle,
            nutritionalValues
        } = this.state;
        const { ingridientsTableHeading, nutritionsTableHeading } = this.props;
        return {
            productLabels,
            productLabelsTitle,
            nutritionalValuesTitle,
            nutritionalValues,
            ingridientsTableHeading,
            nutritionsTableHeading
        };
    };

    _getProductLabels(productId) {
        const { currentStoreId } = this.props;
        fetchQuery(ProductLabelQuery.getProductLabels({
            product_id: productId,
            store_id: currentStoreId
        })).then(
            /** @namespace Scandipwa/Component/ProductLabel/Container/ProductLabelContainer/_getProductLabels/fetchQuery/then */
            ({
                labelByProductIdAndStoreId: {
                    label_content_title, label_content_rows, nutritional_value_title, nutritional_value_rows
                }
            }) => {
                this.setState({
                    productLabelsTitle: label_content_title,
                    productLabels: label_content_rows,
                    nutritionalValuesTitle: nutritional_value_title,
                    nutritionalValues: nutritional_value_rows
                });
            },
            /** @namespace Scandipwa/Component/ProductLabel/Container/ProductLabelContainer/_getProductLabels/fetchQuery/then/catch */
            () => {
                this.setState({
                    productLabelsTitle: [],
                    productLabels: [],
                    nutritionalValuesTitle: [],
                    nutritionalValues: []
                });
            }
        );
    }

    render() {
        return (
            <ProductLabel
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLabelContainer);
