import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductLabel/Query */
export class ProductLabelQuery {
    getProductLabels({ product_id, store_id }) {
        if (!product_id || !store_id) {
            throw new Error('Missing argument `product_id or store_id`');
        }

        return new Field('labelByProductIdAndStoreId')
            .addArgument('product_id', 'Int!', product_id)
            .addArgument('store_id', 'Int!', store_id)
            .addField(this._getNutritionalValueTitle())
            .addField(this._getNutritionalValueRows())
            .addField(this._getLabelContentTitle())
            .addField(this._getLabelContentRows())
            .setAlias('labelByProductIdAndStoreId');
    }

    _getLabelContentTitle() {
        return new Field('label_content_title')
            .addFieldList(this._getLabelContentTitleFields());
    }

    _getLabelContentTitleFields() {
        return [
            'name_title',
            'per_serving_title',
            'rws_title'
        ];
    }

    _getLabelContentRows() {
        return new Field('label_content_rows')
            .addFieldList(this._getLabelContentRowsFields());
    }

    _getLabelContentRowsFields() {
        return [
            'name',
            'per_serving',
            'rws'
        ];
    }

    _getNutritionalValueTitle() {
        return new Field('nutritional_value_title')
            .addFieldList(this._getNutritionalValueTitleFields());
    }

    _getNutritionalValueTitleFields() {
        return [
            'name_title',
            'per_serving_title',
            'in_100g_title'
        ];
    }

    _getNutritionalValueRows() {
        return new Field('nutritional_value_rows')
            .addFieldList(this._getNutritionalValueRowsFields());
    }

    _getNutritionalValueRowsFields() {
        return [
            'name',
            'per_serving',
            'in_100g'
        ];
    }
}

export default new ProductLabelQuery();
