import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductActionsContainer as sourceProductActionsContainer
} from 'SourceComponent/ProductActions/ProductActions.container';
import { updateMeta } from 'Store/Meta/Meta.action';

/** @namespace Scandipwa/Component/ProductActions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Scandipwa/Component/ProductActions/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMeta: (meta) => dispatch(updateMeta(meta))
    // TODO extend mapDispatchToProps
});

/** @namespace Scandipwa/Component/ProductActions/Container */
export class ProductActionsContainer extends sourceProductActionsContainer {
    static propTypes = {
        updateMeta: PropTypes.func.isRequired
    };

    componentDidUpdate(prevProps) {
        const { productOrVariant: { id, name }, updateMeta } = this.props;
        if (prevProps.productOrVariant.id !== id) {
            updateMeta({
                title: name
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductActionsContainer);
