import Html from '@scandipwa/scandipwa/src/component/Html';

import Link from 'Component/Link';
import MarketingClaims from 'Component/MarketingClaims';
import ProductAttributes from 'Component/ProductAttributes';
import { OUT_OF_STOCK } from 'Component/ProductCard/ProductCard.config';
import ProductIcons from 'Component/ProductIcons';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductActions as SourceProductActions
} from 'SourceComponent/ProductActions/ProductActions.component';

import './ProductActions.override.style';

/** @namespace Scandipwa/Component/ProductActions/Component */
export class ProductActionsComponent extends SourceProductActions {
    renderShortDescriptionContent() {
        const { productOrVariant: { short_description } } = this.props;
        const { html } = short_description || {};

        const htmlWithItemProp = `<div itemProp="description">${html}</div>`;

        return (
            // eslint-disable-next-line max-len
            <div
              elem="ShortDescription"
              mix={ { block: 'TypographyParagraph TypographyParagraph_size_normal Typography_light_grey' } }
            >
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { html ? <Html content={ htmlWithItemProp } /> : <p><TextPlaceholder length="long" /></p> }
            </div>
        );
    }

    renderProductAttributes() {
        const { productOrVariant } = this.props;

        return (
            <ProductAttributes product={ productOrVariant } attributesGroupCode="attributes" />
        );
    }

    renderName() {
        const { productOrVariant: { name } } = this.props;

        return (
            <h1
              block="ProductActions"
              elem="Title"
              mix={ {
                  block: 'TypographyHeader',
                  mods: {
                      heading: 'h3'
                  }
              } }
              itemProp="name"
            >
                <TextPlaceholder content={ name } length="medium" />
            </h1>
        );
    }

    renderPriceWithSchema() {
        const {
            productPrice,
            offerCount,
            productOrVariant: {
                stock_status
            }
        } = this.props;

        if (stock_status === OUT_OF_STOCK) {
            return null;
        }

        const {
            minimum_price: {
                final_price: {
                    value: minFinalPrice = 0
                } = {}
            } = {},
            maximum_price: {
                final_price: {
                    value: maxFinalPrice = 0
                } = {}
            } = {}
        } = productPrice;

        return (
            <div
              block="ProductActions"
              elem="PriceWrapper"
            >
                { this.renderSchema() }
                <meta
                  itemProp="highPrice"
                  content={ (minFinalPrice === maxFinalPrice) ? minFinalPrice : maxFinalPrice }
                />
                <ProductPrice
                  isSchemaRequired
                  variantsCount={ offerCount }
                  price={ productPrice }
                  mix={ {
                      block: 'TypographyHeader',
                      mods: {
                          heading: 'h3'
                      }
                  } }
                  label={ this.getConfigurablePriceBadge() }
                />
            </div>
        );
    }

    renderMarketingClaims() {
        const { product } = this.props;
        return (
            <MarketingClaims product={ product } />
        );
    }

    renderProductIcons(withAttribute = null, allWithoutAttribute = null) {
        const { productOrVariant: { id } } = this.props;

        if (!id) {
            return null;
        }

        return (
            <ProductIcons
              productId={ id }
              withAttribute={ withAttribute }
              allWithoutAttribute={ allWithoutAttribute }
              isBigSquare={ !!withAttribute }
            />
        );
    }

    renderProductLinks() {
        const { productOrVariant } = this.props;
        const {
            allegro_link,
            amazon_link,
            ceneo_link
        } = productOrVariant || {};

        const links = [
            { label: 'Allegro', link: allegro_link },
            { label: 'Amazon', link: amazon_link },
            { label: 'Ceneo', link: ceneo_link }]
            .filter((link) => Boolean(link.link));

        if (!links.length) {
            return null;
        }

        return (
            <div block="ProductActions" elem="ProductLinksWrapper">
                <span>{ __('Check where to buy:') }</span>
                <div block="ProductActions" elem="ProductLinks">
                    { links.map(({ label, link }) => (
                        <Link isOpenInNewTab block="Button" mods={ { size: 'small' } } to={ link }>{ label }</Link>
                    )) }
                </div>
            </div>
        );
    }

    render() {
        return (
            <article block="ProductActions">
                { this.renderName() }
                { this.renderProductIcons(null, 'other_icons') }
                { this.renderProductLinks() }
                { this.renderProductAttributes() }
                { this.renderConfigurableAttributes() }
                { this.renderPriceWithGlobalSchema() }
                { this.renderShortDescription() }
                { this.renderProductIcons('other_icons') }
                { this.renderMarketingClaims() }
            </article>
        );
    }
}

export default ProductActionsComponent;
