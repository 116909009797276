import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductTags/Query */
export class ProductTagsQuery {
    getProductTags({ product_id }) {
        if (!product_id) {
            throw new Error('Missing argument `product_id`');
        }

        return new Field('productTagsByProductId')
            .addArgument('product_id', 'Int!', product_id)
            .addField(this._getItemsField())
            .setAlias('productTagsByProductId');
    }

    _getItemFields() {
        return [
            'tag_name',
            'tag_code',
            'tag_url',
            'image_url',
            'description',
            'sort_order',
            'attribute_code'
        ];
    }

    _getItemsField() {
        return new Field('items')
            .addFieldList(this._getItemFields());
    }
}

export default new ProductTagsQuery();
