import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import GroupOfAttributes from './GroupOfAttributes.component';

/** @namespace Scandipwa/Component/GroupOfAttributes/Container */
export class GroupOfAttributesContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        attributesGroupCode: PropTypes.string.isRequired,
        attributeGroupTitle: PropTypes.string.isRequired
    };

    containerProps = () => ({
        groupOfAttributes: this._getGroupOfAttributes()
    });

    _getGroupOfAttributes() {
        const { product: { attributes }, attributesGroupCode } = this.props;
        const attributesArray = attributes ? Object.values(attributes) : [];
        const attributesGroupArray = attributesArray.filter(
            (attr) => attr.attribute_group_code === attributesGroupCode
        );

        return attributesGroupArray;
    }

    render() {
        return (
            <GroupOfAttributes
               /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default GroupOfAttributesContainer;
