import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TypographyHeader from 'Component/TypographyHeader';
import TypographyParagraph from 'Component/TypographyParagraph';

import './ProductLabel.style';

/** @namespace Scandipwa/Component/ProductLabel/Component */
export class ProductLabelComponent extends PureComponent {
    static propTypes = {
        nutritionalValuesTitle: PropTypes.arrayOf(PropTypes.shape({
            name_title: PropTypes.string,
            per_serving_title: PropTypes.string,
            in_100g_title: PropTypes.string
        })).isRequired,
        nutritionalValues: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            per_serving: PropTypes.string,
            in_100g: PropTypes.string
        })).isRequired,
        productLabelsTitle: PropTypes.arrayOf(PropTypes.shape({
            name_title: PropTypes.string,
            per_serving_title: PropTypes.string,
            rws_title: PropTypes.string
        })).isRequired,
        productLabels: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            per_serving: PropTypes.string,
            rws: PropTypes.string
        })).isRequired,
        ingridientsTableHeading: PropTypes.string.isRequired,
        nutritionsTableHeading: PropTypes.string.isRequired
    };

    renderHeader(name, heading, color) {
        return <TypographyHeader heading={ heading } color={ color }>{ name }</TypographyHeader>;
    }

    renderParagraph(content) {
        return <TypographyParagraph size="small">{ content }</TypographyParagraph>;
    }

    // Nutritional Table Functions

    renderNutritionalValuesTitle() {
        const { nutritionalValuesTitle } = this.props;
        return nutritionalValuesTitle && nutritionalValuesTitle.length ? (
            <div block="ProductLabel" elem="Table-Headings">
                { this.renderHeader(nutritionalValuesTitle[0].name_title, 'h6') }
                { this.renderHeader(nutritionalValuesTitle[0].per_serving_title, 'h6') }
                { this.renderHeader(nutritionalValuesTitle[0].in_100g_title, 'h6') }
            </div>
        ) : null;
    }

    renderSingleValue(value) {
        const { name, per_serving, in_100g } = value;
        return (
            <div block="ProductLabel" elem="Table-Content-Row" key={ name }>
                { this.renderParagraph(name) }
                { this.renderParagraph(per_serving) }
                { this.renderParagraph(in_100g) }
            </div>
        );
    }

    renderNutritionalValues() {
        const { nutritionalValues } = this.props;
        return (
            <div block="ProductLabel" elem="Table-Content">
                { nutritionalValues.map((value) => this.renderSingleValue(value)) }
            </div>
        );
    }

    renderNutritionsTable() {
        const { nutritionsTableHeading, nutritionalValues } = this.props;
        return nutritionalValues && nutritionalValues.length ? (
            <div block="ProductLabel" elem="Table">
                { this.renderHeader(nutritionsTableHeading, 'h4', 'blue') }
                { this.renderNutritionalValuesTitle() }
                { this.renderNutritionalValues() }
            </div>
        ) : null;
    }

    // Ingridients Table Functions

    renderProductLabelsTitle() {
        const { productLabelsTitle } = this.props;
        return productLabelsTitle && productLabelsTitle.length ? (
            <div block="ProductLabel" elem="Table-Headings">
                { this.renderHeader(productLabelsTitle[0].name_title, 'h6') }
                { this.renderHeader(productLabelsTitle[0].per_serving_title, 'h6') }
                { this.renderHeader(productLabelsTitle[0].rws_title, 'h6') }
            </div>
        ) : null;
    }

    renderSingleLabel(label) {
        const { name, per_serving, rws } = label;
        return (
            <div block="ProductLabel" elem="Table-Content-Row" key={ name }>
                { this.renderParagraph(name) }
                { this.renderParagraph(per_serving) }
                { this.renderParagraph(rws) }
            </div>
        );
    }

    renderProductLabels() {
        const { productLabels } = this.props;
        return (
            <div block="ProductLabel" elem="Table-Content">
                { productLabels.map((label) => this.renderSingleLabel(label)) }
            </div>
        );
    }

    renderIngridientsTable() {
        const { ingridientsTableHeading, productLabels } = this.props;
        return productLabels && productLabels.length ? (
            <div block="ProductLabel" elem="Table">
                { this.renderHeader(ingridientsTableHeading, 'h4', 'blue') }
                { this.renderProductLabelsTitle() }
                { this.renderProductLabels() }
            </div>
        ) : null;
    }

    renderTableSeparator() {
        return <div block="ProductLabel" elem="Separator" />;
    }

    render() {
        const { nutritionalValues, productLabels } = this.props;
        return nutritionalValues.length || productLabels.length ? (
            <div block="ProductLabel">
                <div block="ProductLabel" elem="Wrapper">
                    { this.renderIngridientsTable() }
                    { this.renderTableSeparator() }
                    { this.renderNutritionsTable() }
                </div>
            </div>
        ) : null;
    }
}

export default ProductLabelComponent;
