import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './ProductDescription.style';

/** @namespace Scandipwa/Component/ProductDescription/Component */
export class ProductDescriptionComponent extends PureComponent {
    static propTypes = {
        html: PropTypes.string
    };

    static defaultProps = {
        html: ''
    };

    render() {
        const { html } = this.props;
        return html ? (
            <section block="ProductDescription">
                <Html content={ html } />
            </section>
        ) : null;
    }
}

export default ProductDescriptionComponent;
