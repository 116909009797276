import Breadcrumbs from 'Component/Breadcrumbs';
import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import GroupOfAttributes from 'Component/GroupOfAttributes';
import Instagram from 'Component/Instagram';
import ProductDescription from 'Component/ProductDescription';
import ProductLabel from 'Component/ProductLabel';
import ProductLinks from 'Component/ProductLinks';
import NoMatchHandler from 'Route/NoMatchHandler';
import {
    ProductPage as SourceProductPage
} from 'SourceRoute/ProductPage/ProductPage.component';
import { RELATED, UPSELL } from 'Store/LinkedProducts/LinkedProducts.reducer';

import './ProductPage.override.style';

/** @namespace Scandipwa/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPage {
    renderProductCompareButton() {
        return null;
    }

    renderInstagram() {
        return <Instagram />;
    }

    renderDescription() {
        const { productOrVariant: { description } } = this.props;
        const { html } = description || {};
        return <ProductDescription html={ html } />;
    }

    renderWhatYouHaveToKnow() {
        const { productOrVariant } = this.props;
        return (
            <GroupOfAttributes
              product={ productOrVariant }
              attributesGroupCode="what-you-have-to-know"
              attributeGroupTitle={ __('What you have to know?') }
            />
        );
    }

    renderProductLabel() {
        const { productOrVariant: { id } } = this.props;

        return id ? (
            <ProductLabel
              productId={ id }
              ingridientsTableHeading={ __('Contains') }
              nutritionsTableHeading={ __('Nutritional value') }
            />
        ) : null;
    }

    renderProductLinks() {
        const {
            areDetailsLoaded
        } = this.props;

        return (
            <>
                <ProductLinks
                  linkType={ RELATED }
                  title={ __('Recommended for you') }
                  areDetailsLoaded={ areDetailsLoaded }
                  withViewButton={ false }
                />
                <ProductLinks
                  linkType={ UPSELL }
                  title={ __('You might also like') }
                  areDetailsLoaded={ areDetailsLoaded }
                  withViewButton={ false }
                />
            </>
        );
    }

    renderAdditionalSections() {
        const { device: { isMobile } } = this.props;

        const bottom = [
            this.renderProductLinks(),
            <CmsBlock identifier="product_opinion" />
        ];

        return (
            <>
                { this.renderProductLabel() }
                { this.renderDescription() }
                { this.renderWhatYouHaveToKnow() }
                { this.renderProductReviewsTab('reviews') }
                { isMobile ? bottom.reverse() : bottom }
                { this.renderInstagram() }
            </>
        );
    }

    render() {
        return (
            <NoMatchHandler>
                <Breadcrumbs />
                <main
                  block="ProductPage"
                  aria-label="Product page"
                  itemScope
                  itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                      wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                      label={ __('Main product details') }
                    >
                        { this.renderProductPageContent() }
                    </ContentWrapper>
                    { this.renderAdditionalSections() }
                    { this.renderReviewPopup() }
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
